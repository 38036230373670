import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { fetchClinics, insertOrUpdateClinic } from "../../state"
import { Layout } from "../../components/Layout"
import { OrganisationsSection as ClinicsSection } from "../../style/organisations"
import { ClinicTable } from "../../components/ClinicTable"
import { BallBeat } from "react-pure-loaders"

function ClinicsContainer({ clinicsData, fetchClinics, user = {} }) {
  useEffect(() => {
    // console.log("fetchClinics being called")
    clinicsData.clinics.length === 0 && fetchClinics(user)
  }, [fetchClinics])

  return (
    <Layout>
      <ClinicsSection>
        <h1 style={{}}>Clinics</h1>
        <br />
        <br />

        <Link
          to="/clinics/clinic"
          style={{
            marginTop: "2rem",
            height: "2rem",
            backgroundColor: "midnightblue",
            color: "white",
            padding: "0.5rem 1rem",
            // paddingTop: ".5rem",
            width: "fit-content",
            borderRadius: "5rem",
            fontWeight: "normal",
            // float: "left",
            // marginBottom: "2rem",
          }}
        >
          + Add Clinic
        </Link>

        {clinicsData.loading ? (
          <>
            <br />
            <br />
            <h4>
              Loading clinics{" "}
              <span>
                <BallBeat color={"#123abc"} loading={clinicsData.loading} />
              </span>{" "}
            </h4>
          </>
        ) : clinicsData.error ? (
          <>
            <br />
            <br />
            <br />

            <h4>Error ⚠️ Fetching Clinics</h4>
            <br />
            <p style={{ color: "grey" }}>{clinicsData.error}</p>
            <br />
          </>
        ) : clinicsData.clinics.length === 0 ? (
          <>
            <br />
            <br />
            <h4>No clinics found</h4>
          </>
        ) : (
          <>
            {/* <h1>Clinics</h1> */}
            {/* <br /> */}
            <br />
            <ClinicTable clinics={clinicsData.clinics} />
          </>
        )}
      </ClinicsSection>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    clinicsData: state.clinics,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClinics: (user) => dispatch(fetchClinics(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicsContainer)
