import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"

export const ClinicTable = ({ clinics }) => {
  const columns = [
    {
      Header: "Clinic ID",
      accessor: "clinicId",
    },
    {
      Header: "Name",
      accessor: "clinicName",
    },

    {
      Header: "Phone No",
      accessor: "clinicPhoneNo",
    },
    {
      Header: "City",
      accessor: "clinicCity",
    },
    {
      Header: "Service Type",
      accessor: "clinicalServiceTypeDesc",
    },
    {
      Header: "Authorized Person",
      accessor: (c) => (c.authorizedPersonName ? c.authorizedPersonName : "NA"),
    },
    {
      Header: "Authorized Contact",
      accessor: (c) => (c.authorizedContactNo ? c.authorizedContactNo : "NA"),
    },
    // {
    //   Header: "Active",
    //   accessor: (isActive) => {
    //     return isActive.isActive ? "Active   " : "Not Active    "
    //   },
    // },
  ]

  var data = React.useMemo(() => clinics, [])

  var tableRender = (
    <Table
      // Pass the page url here to navigate to onClick
      path={"/clinics/clinic"}
      columns={columns}
      data={clinics}
    />
  )

  return <TableStyles>{clinics ? tableRender : " "}</TableStyles>
}
